import "./App.scss";
import logo from "./images/9dot-logo-dark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useState, useEffect } from "react";
import Login from "./Login"; // Import your login component

function App() {
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [client_code, setClientCode] = useState("");
  const [client_name, setClientName] = useState("");
  const [cCodes, setCCode] = useState([]);
  const [clients, setClients] = useState([]);
  const [number, setNumber] = useState();
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [notes, setNotes] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [originalClientCode, setOriginalClientCode] = useState("");
  const [originalJobNumber, setOriginalJobNumber] = useState("");
  const [originalClientProject, setOriginalClientProject] = useState("");
  const [originalNotes, setOriginalNotes] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [editedClientId, setEditedClientId] = useState(null);
  const [editedClientCode, setEditedClientCode] = useState("");
  const [editedClientName, setEditedClientName] = useState("");

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    axios
      .get("https://9dotdigital.ca/wp-json/wl/v1/jobs")
      .then((res) => {
        setJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
      }, []);

    axios
      .get("https://9dotdigital.ca/wp-json/wl/v1/jobs")
      .then((res) => {
        const arr = res.data;

        // Adding Job Code Number
        const jobNumbers = [];
        arr.forEach((element) => jobNumbers.push(element.number));
        jobNumbers.reverse();
        const addJobNumber = jobNumbers.at(-1) + 1;
        setNumber(addJobNumber);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("https://9dotdigital.ca/wp-json/wl/v1/codes")
      .then((res) => {
        const arr = res.data;
        const clientCodes = [];
        arr.forEach((element) => clientCodes.push(element.client_code));
        setCCode(clientCodes);

        setClients(arr);
      })
      .catch((err) => {
        console.log(err);
      });

    if (!document.querySelector('meta[name="robots"]')) {
      document.head.innerHTML += `<meta name='robots' content='noindex, nofollow'>`;
    }
  }, []);

  // Login functionality
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let split = el.split("=");
      cookie[split[0].trim()] = split.slice(1).join("=");
    });
    return cookie[name];
  }

  if (!isAuthenticated && !getCookie("9dotisAuthenticated")) {
    return <Login onLogin={handleLogin} />;
  }

  const handleClientSubmit = (e) => {
    e.preventDefault();
    const c = { client_code, client_name };
    const co = client_code;

    fetch("https://9dotdigital.ca/wp-json/wl/v1/codes", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(c),
    }).then(() => {
      cCodes.push(co);
      setCCode([...cCodes]);

      clients.push(c);
      setClients([...clients]);
    });

    setClientCode("");
    setClientName("");
  };

  const handleProjectSubmit = async (e) => {
    e.preventDefault();
    const job = { title, notes, code, number };

    try {
      await fetch("https://9dotdigital.ca/wp-json/wl/v1/jobs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(job),
      }).then(() => {
        jobs.push(job);
        const reverse = [...jobs];
        reverse.reverse();
        setJobs(reverse);
        // jobs.push(job);
        // console.log(jobs);
        // setJobs([...jobs]);
      });
    } catch (error) {
      console.log(error);
    }

    setCode("");
    setTitle("");
    setNotes("");
    setTimeout(function () {
      window.location.reload();
    }, 10);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  const removeData = (id) => {
    axios.delete(`https://9dotdigital.ca/wp-json/wl/v1/jobs/${id}`).then(() => {
      const del = jobs.filter((job) => id !== job.id);
      setJobs(del);
      setTimeout(function () {
        window.location.reload();
      }, 10);
    });
  };

  const handleEditClick = (id, code, number, title, notes) => {
    setEditIndex(id);
    setOriginalClientCode(code);
    setOriginalJobNumber(number);
    setOriginalClientProject(title);
    setOriginalNotes(notes);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    console.log(originalNotes);

    try {
      await fetch(`https://9dotdigital.ca/wp-json/wl/v1/jobs/${editIndex}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          number: originalJobNumber,
          code: originalClientCode,
          title: originalClientProject,
          notes: originalNotes,
        }),
      });
      setTimeout(function () {
        window.location.reload();
      }, 10);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleEditClientChange = (e, id, client_code, client_name) => {
    setEditedClientId(e.target.value);
    const editedId = e.target.value;

    axios
      .get(`https://9dotdigital.ca/wp-json/wl/v1/codes/${editedId}`)
      .then((res) => {
        console.log(res.data);
        setEditedClientCode(res.data.client_code);
        setEditedClientName(res.data.client_name);
      })
      .catch((err) => {
        console.log(err);
      });

    setEditedClientCode(client_code);
    setEditedClientName(client_name);
  };

  const handleEditClientSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://9dotdigital.ca/wp-json/wl/v1/codes/${editedClientId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_code: editedClientCode,
            client_name: editedClientName,
          }),
        }
      );
      await response.json();
    } catch (error) {
      console.error(error);
    }

    setTimeout(function () {
      window.location.reload();
    }, 10);
  };

  const resetFilterClick = () => {
    setFilter("");
  };

  return (
    <div className="App wrapper">
      <header className="header">
        <img src={logo} alt="logo" className="logo" />
      </header>
      <div className="form-group">
        <div className="form">
          <h3 className="form__heading">Add a Client</h3>
          <div className="form__container">
            <form onSubmit={handleClientSubmit}>
              <input
                type="text"
                placeholder="Client Code"
                value={client_code}
                onChange={(e) => setClientCode(e.target.value)}
                className="short-input-code"
              />
              <input
                type="text"
                placeholder="Client Name"
                value={client_name}
                onChange={(e) => setClientName(e.target.value)}
                className="long-input-name"
              />
              <button>Create</button>
            </form>
            <button onClick={toggleVisibility} className="edit-client-button">
              Edit Client
            </button>
          </div>
        </div>
        <div style={{ display: isVisible ? "block" : "none" }} className="form">
          <h3 className="form__heading">Edit Client</h3>
          <form onSubmit={handleEditClientSubmit} className="form__edit-client">
            <select value={editedClientId} onChange={handleEditClientChange}>
              <option key="choose" value="">
                Client
              </option>
              {clients
                .filter((client) => client && client.client_code) // Filter out null or undefined values
                .sort((a, b) => a.client_code.localeCompare(b.client_code))
                .map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.client_code}
                  </option>
                ))}
            </select>
            <input
              type="text"
              value={editedClientCode}
              onChange={(e) => setEditedClientCode(e.target.value)}
              className="short-input-code"
            />
            <input
              type="text"
              value={editedClientName}
              onChange={(e) => setEditedClientName(e.target.value)}
              className="long-input-name"
            />
            <button className="update-client">Update Client</button>
          </form>
        </div>
        <div className="form">
          <h3 className="form__heading">Add a Project</h3>
          <form onSubmit={handleProjectSubmit}>
            <select value={code} onChange={(e) => setCode(e.target.value)}>
              <option key="choose" value="">
                Client
              </option>
              {cCodes
                .filter((cCode) => cCode !== null && cCode !== undefined) // Filter out null or undefined values
                .sort((a, b) => (a || "").localeCompare(b || ""))
                .map((cCode) => (
                  <option key={cCode} value={cCode}>
                    {cCode}
                  </option>
                ))}
            </select>
            <input
              type="text"
              placeholder="Project Name"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="long-input"
            />
            <input
              type="text"
              placeholder="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="longest-input"
            />
            <button>Create</button>
          </form>
        </div>
      </div>
      <div className="content">
        <aside className="sidebar-container">
          <h2>Filters</h2>
          <div className="sidebar">
            <form className="search" onSubmit={handleSearchSubmit}>
              <input
                className="search-field"
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="search-button">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </form>
            <div className="header-button">
              <h3>Filter by Client:</h3>
              <button onClick={resetFilterClick}>Reset Filter</button>
            </div>
            <form className="filter">
              {clients
                .sort((a, b) =>
                  a.client_name && b.client_name
                    ? a.client_name.localeCompare(b.client_name)
                    : 0
                )
                .sort((a, b) =>
                  a.client_code && b.client_code
                    ? a.client_code.localeCompare(b.client_code)
                    : 0
                )
                .map((client, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      id={client.client_code}
                      value={client.client_code}
                      name="client"
                      onChange={(e) => setFilter(e.target.value)}
                    />
                    {client.client_name}
                  </div>
                ))}
            </form>
          </div>
        </aside>
        <div className="table">
          <h2>Latest Projects</h2>
          <div className="table__container">
            <div className="table__row-heading">
              <div className="table__item">
                <h3>Project Code</h3>
              </div>
              <div className="table__item">
                <h3>Project Name</h3>
              </div>
            </div>
            <ul className="table-list">
              {jobs
                // .filter((job) => {
                //     return search === '' ? job : job.title.includes(search);
                // })
                // .filter((job) => {
                //     return filter === '' ? job : job.code.includes(filter);
                // })
                .filter((job) => {
                  return (
                    search === "" ||
                    job.title?.toLowerCase().includes(search.toLowerCase())
                  );
                })
                .filter((job) => {
                  return (
                    filter === "" ||
                    job.code?.toLowerCase().includes(filter.toLowerCase())
                  );
                })
                .map((job, id) => (
                  <li className="table__row" key={job.id}>
                    {editIndex === job.id ? (
                      <form className="editing" onSubmit={handleEditSubmit}>
                        <select
                          value={originalClientCode}
                          onChange={(e) =>
                            setOriginalClientCode(e.target.value)
                          }
                        >
                          <option key="choose" value="">
                            Client
                          </option>
                          {cCodes
                            .filter(
                              (cCode) => cCode !== null && cCode !== undefined
                            ) // Filter out null or undefined values
                            .sort((a, b) => a.localeCompare(b))
                            .map((cCode) => (
                              <option key={cCode} value={cCode}>
                                {cCode}
                              </option>
                            ))}
                        </select>
                        <input
                          type="number"
                          placeholder={job.number}
                          value={originalJobNumber}
                          onChange={(e) => setOriginalJobNumber(e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder={job.title}
                          value={originalClientProject}
                          onChange={(e) =>
                            setOriginalClientProject(e.target.value)
                          }
                        />
                        <input
                          type="text"
                          placeholder={job.notes}
                          value={originalNotes}
                          onChange={(e) => setOriginalNotes(e.target.value)}
                        />
                        <button>Edit</button>
                      </form>
                    ) : (
                      <div className="standard">
                        <p className="table__item table__item--project-code">
                          {job.code} {job.number}
                        </p>
                        <p className="table__item">
                          {job.title.replace(`&amp;`, "&")}
                          <span>{job.notes}</span>
                        </p>
                      </div>
                    )}
                    <button
                      onClick={() =>
                        handleEditClick(
                          job.id,
                          job.code,
                          job.number,
                          job.title,
                          job.notes
                        )
                      }
                      className="edit"
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button
                      className="delete"
                      onClick={() => removeData(job.id)}
                    ></button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
