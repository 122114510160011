import React, { useState } from "react";
import logo from "./images/9dot-logo-dark.svg";
import "./Login.scss";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simple authentication logic (replace with actual auth in production)
    if (username === "9dot" && password === "c0ppc0mm5383") {
      onLogin();
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      let cookieValue = "true";
      document.cookie =
        "9dotisAuthenticated = " +
        cookieValue +
        "; expires = " +
        date.toGMTString() +
        ";path=/";
    } else {
      setError("Invalid credentials");
    }
  };

  return (
    <div className="login-wrapper">
      <header className="header">
        <img src={logo} alt="logo" className="logo" />
      </header>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username: </label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Password: </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
